var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"answer"},[_c('div',{staticClass:"answer_top"},[_c('span',{staticClass:"answer_num"},[_vm._v(_vm._s(_vm.answerNum))]),_c('span',[_vm._v("个答案")]),_c('span',{staticClass:"answer_top_bottom"})]),_vm._l((_vm.basiced),function(items){return _c('div',{key:items.wdanswerref,staticClass:"answer_content"},[_c('div',{staticClass:"answer_content_top"},[_c('span',{staticClass:"personal_left"},[_c('img',{staticClass:"personal_left_img",attrs:{"src":items.img}})]),_c('span',{staticClass:"answer_content_center"},[_c('div',{staticClass:"personal_name"},[_c('span',{staticClass:"personal_names"},[_vm._v(_vm._s(items.name))]),(items.adopt == 1)?_c('span',{staticStyle:{"padding":"10px","border-radius":"50%","border":"1px solid #eca67e","box-shadow":"0px 2px 13px 0px rgba(87, 87, 87, 0.44)","font-size":"20px","font-family":"Microsoft YaHei","font-weight":"800","font-style":"italic","color":"#FD6E05"}},[_vm._v("被采纳")]):_vm._e()]),_c('div',{staticClass:"personal_autograph"},[_vm._v(_vm._s(items.intro))])])]),_c('div',{staticClass:"answer_content_bottom"},[_c('div',[(items.answer.length < 30 && _vm.piddd != items.wdanswerref)?_c('span',{ref:"answer",refInFor:true,staticClass:"details_content"},[_vm._v(_vm._s(items.answer))]):_vm._e(),(
            items.answer.length > 30 &&
              items.answer.length < 100 &&
              _vm.piddd != items.wdanswerref
          )?_c('span',{ref:"answer",refInFor:true,staticClass:"details_content"},[_vm._v(_vm._s(items.answer.substring(0, 30) + "......"))]):_vm._e(),(
            items.answer.length > 100 &&
              items.answer.length < 500 &&
              _vm.piddd != items.wdanswerref
          )?_c('span',{ref:"answer",refInFor:true,staticClass:"details_content"},[_vm._v(_vm._s(items.answer.substring(0, 200) + "......"))]):_vm._e(),(
            items.answer.length > 500 &&
              items.answer.length < 1000 &&
              _vm.piddd != items.wdanswerref
          )?_c('span',{ref:"answer",refInFor:true,staticClass:"details_content"},[_vm._v(_vm._s(items.answer.substring(0, 400) + "......"))]):_vm._e(),(items.answer.length > 1000 && _vm.piddd != items.wdanswerref)?_c('span',{ref:"answer",refInFor:true,staticClass:"details_content"},[_vm._v(_vm._s(items.answer.substring(0, 600) + "......"))]):_vm._e(),(_vm.piddd == items.wdanswerref && _vm.visable == 1)?_c('span',{ref:"answer",refInFor:true,staticClass:"details_content"},[_vm._v(_vm._s(items.answer))]):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.piddd != items.wdanswerref &&
              _vm.visable == 1 &&
              items.answer.length > 30
          ),expression:"\n            piddd != items.wdanswerref &&\n              visable == 1 &&\n              items.answer.length > 30\n          "}],staticClass:"open",on:{"click":function($event){return _vm.opens(items.wdanswerref)}}},[_vm._v("展开")])]),_c('div',{staticClass:"details_content_open"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.piddd == items.wdanswerref && _vm.visable == 1),expression:"piddd == items.wdanswerref && visable == 1"}],staticClass:"away",on:{"click":function($event){return _vm.aways(items.wdanswerref)}}},[_vm._v("收起")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
          items.answerFiles != _vm.API + undefined &&
            items.answerFiles != _vm.API &&
            _vm.visable == 1
        ),expression:"\n          items.answerFiles != API + undefined &&\n            items.answerFiles != API &&\n            visable == 1\n        "}],staticStyle:{"color":"#0084FF","font-size":"15px","cursor":"pointer"},on:{"click":function($event){return _vm.see(items.answerFiles)}}},[_vm._v("预览附件")]),(items.imge != _vm.API && _vm.visable == 1)?_c('img',{staticStyle:{"margin-right":"10px"},attrs:{"width":"150px","height":"120px","src":items.imge,"alt":""}}):_vm._e(),(
          items.videos != _vm.API + undefined &&
            items.videos != _vm.API &&
            _vm.visable == 1
        )?_c('video',{staticStyle:{"border":"1px solid rgba(220,220,220,1)"},attrs:{"src":items.videos,"controls":"controls","width":"200px","height":"120px"}},[_vm._v("您的浏览器不支持 video 标签。")]):_vm._e(),(_vm.visable == 1)?_c('div',{staticClass:"time"},[_vm._v(_vm._s(items.createtimes))]):_vm._e(),(_vm.visable == 1)?_c('div',{staticClass:"yin1"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"引用答案后不可修改,只能取消引用","placement":"top-start"}},[_c('el-button',{staticClass:"yin",on:{"click":function($event){return _vm.close(items.wdanswerref,items.answer)}}})],1)],1):_vm._e()])])}),(_vm.Type == 2 && _vm.visable != 1)?_c('div',{staticClass:"open",on:{"click":_vm.quanbu1}},[_vm._v(" 继续阅读需 "),_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(_vm._s(_vm.viewPrice))]),_vm._v("知识币 "),_c('span',{staticClass:"down"})]):_vm._e(),(_vm.Type == 1 && _vm.visable != 1)?_c('div',{staticClass:"open",on:{"click":_vm.open}},[_vm._v(" 展开全部 "),_c('span',{staticClass:"down"})]):_vm._e(),(_vm.visable == 1)?_c('div',{staticClass:"opens",on:{"click":_vm.init}},[_vm._v(" 收起 "),_c('span',{staticClass:"up"})]):_vm._e(),(_vm.basiced.length == 0)?_c('div',{staticStyle:{"font-size":"20px","color":"rgb(153, 153, 153)","width":"100%","text-align":"center"}},[_vm._v("暂无答案")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }