<template>
  <div class="answer">
    <!-- 多少个答案 -->
    <div class="answer_top">
      <span class="answer_num">{{ answerNum }}</span>
      <span>个答案</span>
      <span class="answer_top_bottom"></span>
    </div>
    <!-- 答案详情 -->
    <div class="answer_content" v-for="items in basiced" :key="items.wdanswerref">
      <!-- 头部 -->
      <div class="answer_content_top">
        <!-- 头像 -->
        <span class="personal_left">
          <img class="personal_left_img" :src="items.img" />
        </span>
        <span class="answer_content_center">
          <!-- 姓名 -->
          <div class="personal_name">
            <span class="personal_names">{{ items.name }}</span>
            <span
              v-if="items.adopt == 1"
              style="  
                      padding:10px;  
                      border-radius: 50%;             
                      border: 1px solid #eca67e;
                      box-shadow: 0px 2px 13px 0px rgba(87, 87, 87, 0.44);
                      font-size: 20px;
                      font-family: Microsoft YaHei;
                      font-weight: 800;
                      font-style: italic;
                      color: #FD6E05;"
            >被采纳</span>
          </div>
          <!-- 签名 -->
          <div class="personal_autograph">{{ items.intro }}</div>
        </span>
      </div>
      <!--答案 具体详情 -->
      <div class="answer_content_bottom">
        <!-- 答案 -->
        <div>
          <!-- 初始化 -->
          <span
            class="details_content"
            ref="answer"
            v-if="items.answer.length < 30 && piddd != items.wdanswerref"
          >{{ items.answer }}</span>
          <span
            class="details_content"
            ref="answer"
            v-if="
              items.answer.length > 30 &&
                items.answer.length < 100 &&
                piddd != items.wdanswerref
            "
          >{{ items.answer.substring(0, 30) + "......" }}</span>
          <span
            class="details_content"
            ref="answer"
            v-if="
              items.answer.length > 100 &&
                items.answer.length < 500 &&
                piddd != items.wdanswerref
            "
          >{{ items.answer.substring(0, 200) + "......" }}</span>
          <span
            class="details_content"
            ref="answer"
            v-if="
              items.answer.length > 500 &&
                items.answer.length < 1000 &&
                piddd != items.wdanswerref
            "
          >{{ items.answer.substring(0, 400) + "......" }}</span>
          <span
            class="details_content"
            ref="answer"
            v-if="items.answer.length > 1000 && piddd != items.wdanswerref"
          >{{ items.answer.substring(0, 600) + "......" }}</span>
          <!-- 展开 -->
          <span
            class="details_content"
            ref="answer"
            v-if="piddd == items.wdanswerref && visable == 1"
          >{{ items.answer }}</span>
          <span
            v-show="
              piddd != items.wdanswerref &&
                visable == 1 &&
                items.answer.length > 30
            "
            @click="opens(items.wdanswerref)"
            class="open"
          >展开</span>
        </div>
        <div class="details_content_open">
          <!-- <span v-show="piddd!=items.wdanswerref&&visable==1" @click="opens(items.wdanswerref)" class="open">展开
          </span>-->
          <span
            v-show="piddd == items.wdanswerref && visable == 1"
            @click="aways(items.wdanswerref)"
            class="away"
          >收起</span>
        </div>
        <!-- 图片 视频 附件 -->
        <!-- 附件 -->
        <span
          style="color:#0084FF;font-size: 15px;cursor: pointer;"
          v-show="
            items.answerFiles != API + undefined &&
              items.answerFiles != API &&
              visable == 1
          "
          @click="see(items.answerFiles)"
        >预览附件</span>
        <!-- 图片显示 -->
        <img
          v-if="items.imge != API && visable == 1"
          width="150px"
          height="120px"
          :src="items.imge"
          alt
          style=" margin-right:10px"
        />
        <!-- 视频显示 -->
        <video
          v-if="
            items.videos != API + undefined &&
              items.videos != API &&
              visable == 1
          "
          :src="items.videos"
          controls="controls"
          width="200px"
          height="120px"
          style="border :1px solid rgba(220,220,220,1)"
        >您的浏览器不支持 video 标签。</video>
        <!-- 时间 -->
        <div class="time" v-if="visable == 1">{{ items.createtimes }}</div>
        <div class="yin1" v-if="visable == 1">
          <!-- <span class="yin" @click="close(items.wdanswerref)"></span> -->
          <el-tooltip class="item" effect="light" content="引用答案后不可修改,只能取消引用" placement="top-start">
            <el-button class="yin" @click="close(items.wdanswerref,items.answer)"></el-button>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="open" v-if="Type == 2 && visable != 1" @click="quanbu1">
      继续阅读需
      <span style="color:red;font-weight: bold;">{{ viewPrice }}</span>知识币
      <span class="down"></span>
    </div>
    <!-- 如果是收费 -->
    <div class="open" v-if="Type == 1 && visable != 1" @click="open">
      展开全部
      <span class="down"></span>
    </div>
    <!-- 收起 -->
    <div class="opens" v-if="visable == 1" @click="init">
      收起
      <span class="up"></span>
    </div>

    <div
      v-if="basiced.length == 0"
      style="font-size: 20px;color: rgb(153, 153, 153);width:100%;text-align:center"
    >暂无答案</div>
  </div>
</template>
<script>
import axios from "axios";
import { get, post } from "@/services/http.js";
import { DELETE, QUERYED, INSERT, UPDATE, QUERY } from "@/services/dao.js";
import {
  updatedownload,
  formatZero,
  getTimeFormat
} from "@/js/common/Resoures";
import {
  dataFormat,
  formatDate,
  integralAddTwo,
  showTimeFormat,
  AcceptNum,
  AcceptNumTeam
} from "@/js/common/index.js";
export default {
  data() {
    return {
      API: "", //请求接口
      basiced: [], //回答显示数组
      answerNum: 0, //答案个数
      Type: 0, //类型
      visable: 0, //是否初始化显示
      viewPrice: 0, //查看分成的价格
      piddd: -1 //是否展开
    };
  },
  props: {
    IDS: {
      type: String,
      required: true
    }
  },
  created() {
    //alert(this.IDS)问题ID
    this.API = this.api.LoginURL.concat();
    this.init(); //初始化显示详情
  },
  methods: {
    ////下载附件
    async see(answerFiles) {
      //  window.URL.createObjectURL=answerFiles;
      //  window.location.href =this.api.LoginURL.concat( answerFiles);
      window.open(answerFiles, "_blank"); ///新建窗口
    },
    //初始化显示详情
    async initialization() {},
    async opens(id) {
      this.piddd = id;
    },
    async aways(id) {
      this.piddd = 0;
    },
    //   展开免费
    // 引用
    async close(id, answer) {
      const formData = new FormData();
      formData.append("text", answer);

      post("/common/chachong", formData).then(res => {
        console.log("重复率：", res.data);
        if (res.data > 0.8) {
          this.$confirm("此回答可能不属于原创，是否确定引用？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              this.$emit("closeYy", id, this.IDS);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消引用！"
              });
            });
        } else {
          this.$emit("closeYy", id, this.IDS);
        }
      });
    },
    ////展开回答（收费）
    async quanbu1() {
      let user = this.$store.state.userinfo.id;
      if (user == "" || user == null || user < 0) {
        this.$message({
          type: "warning",
          message: "您还未登录，请您先登录！"
        });
      } else {
        ///判断是否已经购买
        let ddata = await QUERYED(
          "post",
          "",
          "  WDALLBill(where: {userid: {_eq: " +
            user +
            "}, orderid: {_eq: " +
            this.IDS +
            "}}) {  time  orderNum  id   }"
        );
        if (ddata.data.WDALLBill.length > 0) {
          ////如果已经购买
          this.$message({
            type: "success",
            message: "您已经购买过！请直接查看！"
          });
          this.charge(); ////显示全部问题
        } else {
          // 如果没有购买
          ///根据登陆账号查找账户余额
          let data = await QUERYED(
            "post",
            "",
            "  PCUsers(where: {id: {_eq: " + user + "}}) {   Money  }"
          );
          this.$confirm(
            "您的账号知识币余额为" +
              data.data.PCUsers[0].Money.toFixed(2) +
              "，您确定要支付" +
              this.viewPrice +
              "知识币查看答案吗？",
            "系统提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }
          )
            ///确认按钮
            .then(() => {
              this.purchase(user, data.data.PCUsers[0].Money); //同意购买（传递登陆者的账户余额）
              // this.AcceptNumed();//被采纳的回答采纳数修改
            })
            // 取消按钮
            .catch(() => {
              console.log("取消订单！");
            });
        }
      }
    },
    // 修改用户和回答的采纳数
    async AcceptNumed() {
      // 查找回答问题被采纳的回答者ID
      let AnswerRefID = await QUERYED(
        "POST",
        "",
        "  WDUserQuestionAnswerRef(where: {adopt: {_eq: 1}, wdquestionid: {_eq: " +
          this.IDS +
          "}}) {   wdanswerref  }"
      );
      console.log(
        "采纳者ID" + AnswerRefID.data.WDUserQuestionAnswerRef[0].wdanswerref
      );
      AcceptNum(AnswerRefID.data.WDUserQuestionAnswerRef[0].wdanswerref); //传递用户ID(修改用户)
      AcceptNumTeam(AnswerRefID.data.WDUserQuestionAnswerRef[0].wdanswerref); //传递用户ID（修改团队）
    },
    ///购买事件
    async purchase(user, Money) {
      ////查询购买状态
      let ddata = await QUERYED(
        "post",
        "",
        "  WDALLBill(where: {userid: {_eq: " +
          user +
          "}}) {  time  orderNum  id   }"
      );
      ///如果账户余额不足
      if (Money < this.viewPrice) {
        ////给出余额不足的警告
        this.$alert(
          "您的账户余额不足，请你先到个人中心充值后在购买！",
          "系统提示：",
          {
            dangerouslyUseHTMLString: true
          }
        );
      } else {
        //  如果余额充足
        // 查找回答问题被采纳的回答者ID
        let AnswerRefID = await QUERYED(
          "POST",
          "",
          "WDUserQuestionAnswerRef(where: {adopt: {_eq: 1}, wdquestionid: {_eq: " +
            this.IDS +
            "}}) {   user  }"
        );
        ///根据问题ID查找发布问题者的ID
        let question = await QUERYED(
          "post",
          "",
          "WDQuestion(where: {id: {_eq: " + this.IDS + "}}) {   questioner  }"
        );
        if (AnswerRefID.data.WDUserQuestionAnswerRef[0].user == user) {
          //如果登陆账号是被采纳者
          ////给出余额不足的警告
          this.$alert("您是被采纳者无需购买，可直接查看！", "系统提示：", {
            dangerouslyUseHTMLString: true
          });
          this.charge(); ///展开全部
        } else {
          ///如果是第三方查看（第一层）
          // 判断该回答有无被引用
          let quetos = await QUERYED(
            "POST",
            "",
            "WdAnswerRef(where: {question: {_eq:" +
              this.IDS +
              "},adopt: {_eq: 1}}) {   id person quetoType quetoAnswer external_teamid  quetoPloblem  }"
          );
          if (quetos.data.WdAnswerRef[0].quetoType == 0) {
            //如果没有被引用
            // 包括多人和单人问答
            this.Noqueto(
              Money,
              user,
              this.IDS,
              quetos.data.WdAnswerRef[0].person,
              quetos.data.WdAnswerRef[0].external_teamid
            ); ///无引用（单人、多人）支付
          } else {
            ///如果被引用了
            // 通过问题ID查找判断引用的问题是否是查看分成
            let answer = await QUERYED(
              "POST",
              "",
              "WDQuestion(where: {id: {_eq:" +
                quetos.data.WdAnswerRef[0].quetoPloblem +
                "}}) {  id     isViewFree  }"
            );
            if (answer.data.WDQuestion[0].isViewFree == 1) {
              ///如果引用免费的
              this.Noqueto(
                Money,
                user,
                this.IDS,
                quetos.data.WdAnswerRef[0].person,
                quetos.data.WdAnswerRef[0].external_teamid
              ); ///无引用（单人、多人）支付
            } else if (answer.data.WDQuestion[0].isViewFree != 1) {
              ///引用收费答案
              let DATE = new Date();
              let IDSE = Number(this.IDS);
              /////////////////////先给登陆账户扣除费用///////
              ///查找回答ID
              let AnswerRefIDe = await QUERYED(
                "POST",
                "",
                "WDUserQuestionAnswerRef(where: {adopt: {_eq: 1}, wdquestionid: {_eq: " +
                  this.IDS +
                  "}}) {   wdanswerref}"
              );
              console.log(
                "回答ID:" +
                  AnswerRefIDe.data.WDUserQuestionAnswerRef[0].wdanswerref
              );
              //////访问后台计算引用收费问题quesId,answerId，time
              axios({
                method: "post",
                url: this.api.LoginURL.concat() + "/common/share",
                params: {
                  quesId: IDSE, //问题ID
                  answerId:
                    AnswerRefIDe.data.WDUserQuestionAnswerRef[0].wdanswerref, //回答ID
                  time: String(DATE),
                  userId: user //登陆用户
                },
                headers: {
                  "Content-Type": "application/json",
                  token: localStorage.getItem("Authorization")
                }
              })
                .then(response => {
                  console.log("加载成功：" + JSON.stringify(response.data));
                  if (response.data.respCode == 0) {
                    this.$message({
                      type: "success",
                      message: "购买成功！"
                    });
                    this.charge(); ///展开全部
                  } else if (response.data.respCode == -1) {
                    //alert("服务器错误！请重新刷新！");
                    this.$message({
                      message: "服务器错误！请重新刷新！",
                      type: "warning"
                    });
                  } else if (
                    response.data.respCode == -2 ||
                    response.data.respCode == -9
                  ) {
                    //alert("登录超时，请重新登录！");
                    this.$message({
                      message: "登录超时，请重新登录！",
                      type: "warning"
                    });
                  }
                })
                .catch(function(error) {
                  console.log("错误" + error);
                  //alert("加载失败,请重新刷新！");
                  this.$message({
                    message: "加载失败,请重新刷新",
                    type: "warning"
                  });
                });
            }
          }
        }
      }
    },
    // 无引用查看////单人回答和多人回答
    async Noqueto(Money, user, IDS, person, teamid) {
      ////查询购买状态（购买者）
      let ddata = await QUERYED(
        "post",
        "",
        "  WDALLBill(where: {userid: {_eq: " +
          user +
          "}}) {  time  orderNum  id   }"
      );
      // 查找回答问题被采纳的回答者ID
      let AnswerRefID = await QUERYED(
        "POST",
        "",
        "WDUserQuestionAnswerRef(where: {adopt: {_eq: 1}, wdquestionid: {_eq: " +
          IDS +
          "}}) {   user external_teamid external_problemid }"
      );
      ///根据问题ID查找发布问题者的ID
      let question = await QUERYED(
        "post",
        "",
        "WDQuestion(where: {id: {_eq: " + IDS + "}}) {   questioner  }"
      );
      // 扣费
      Money = Number(Money) - Number(this.viewPrice); ///扣费后的余额
      Money == (Math.round(Money * 100) / 100).toFixed(2); ////保留两位小数
      ///修改个人的账户余额
      let info = await UPDATE(
        "post",
        "",
        "  update_User(where: {id: {_eq: " +
          user +
          "}}, _set: {Money: " +
          Money +
          "}) { affected_rows  }"
      );
      if (info.data.update_User.affected_rows == 1) {
        //如果账户修改成功
        let orderNum = "";
        let num = String(formatZero(user, 7));
        /////生成订单编号
        if (ddata.data.WDALLBill.length < 1) {
          orderNum = getTimeFormat(new Date()) + num + "001";
        } else {
          for (let i = 0; i < ddata.data.WDALLBill.length; i++) {
            if (
              getTimeFormat(new Date()) ==
              getTimeFormat(ddata.data.WDALLBill[i].time)
            ) {
              let sic = ddata.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
              orderNum =
                getTimeFormat(new Date()) + num + String(formatZero(sic, 3));
              break;
            } else {
              orderNum = getTimeFormat(new Date()) + num + "001";
            }
          }
        }
        // console.log(
        //   "时间11" + new Date(),
        //   "用户ID" + user,
        //   "订单编号" + orderNum,
        //   "订单ID" + this.problemid,
        //   "价钱" + viewPrice
        // );
        ///我的扣费账单 ----生成账单
        let data1 = await INSERT(
          "post",
          "",
          '  insert_bill(objects: {type: 1, state: 0, time: "' +
            new Date() +
            '", userid: ' +
            user +
            ', orderNum: "' +
            orderNum +
            '", orderid: ' +
            IDS +
            ', Price: "' +
            this.viewPrice +
            '", evaluate: "",classification:1}) {affected_rows    returning {    id  }  }'
        );
        if (data1.data.insert_bill.affected_rows == 1) {
          console.log("查看问题者的账单生成成功！");
          // 查找该问题的分成比例
          let FCBL = await QUERYED(
            "POST",
            "",
            "WDQuestion(where:{id: {_eq:" +
              IDS +
              "}}) {questionerPercentage answererPercentage}"
          );
          let release =
            (this.viewPrice *
              Number(FCBL.data.WDQuestion[0].questionerPercentage)) /
            100; // 问题发布者所需获得的价钱
          release == (Math.round(release * 100) / 100).toFixed(2); ////保留两位小数
          let answer =
            (this.viewPrice *
              Number(FCBL.data.WDQuestion[0].answererPercentage)) /
            100; //问题回答者所需获得的价钱
          answer == (Math.round(answer * 100) / 100).toFixed(2); ////保留两位小数
          console.log("发布者价钱：" + release + "采纳者价钱：" + answer);
          ///查找发布问题者的知识币余额
          let Moenys = await QUERYED(
            "POST",
            "",
            "  PCUsers(where: {id: {_eq: " +
              question.data.WDQuestion[0].questioner +
              "}}) {   Money  }"
          );
          Moenys.data.PCUsers[0].Money =
            Number(Moenys.data.PCUsers[0].Money) + Number(release); ///发布者的账户余额（账户添加价钱成功）
          //修改发布问题者的账户余额
          let updateMoeny = await UPDATE(
            "POST",
            "",
            "  update_User(where: {id: {_eq: " +
              question.data.WDQuestion[0].questioner +
              "}}, _set: {Money: " +
              Moenys.data.PCUsers[0].Money +
              "}) {  affected_rows  }"
          );
          if (updateMoeny.data.update_User.affected_rows == 1) {
            //////////////////问题发布者添加一条账单
            ////问题发布者查询购买状态
            let ddata1 = await QUERYED(
              "post",
              "",
              "  WDALLBill(where: {userid: {_eq: " +
                question.data.WDQuestion[0].questioner +
                "}}) {  time  orderNum  id   }"
            );
            //  生成回答问题者的订单编号
            let orderNum1 = "";
            let num1 = String(
              formatZero(question.data.WDQuestion[0].questioner, 7)
            );
            /////生成订单编号
            if (ddata1.data.WDALLBill.length < 1) {
              orderNum1 = getTimeFormat(new Date()) + num1 + "001";
            } else {
              for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
                if (
                  getTimeFormat(new Date()) ==
                  getTimeFormat(ddata1.data.WDALLBill[i].time)
                ) {
                  let sic =
                    ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
                  orderNum1 =
                    getTimeFormat(new Date()) +
                    num1 +
                    String(formatZero(sic, 3));
                } else {
                  orderNum1 = getTimeFormat(new Date()) + num1 + "001";
                }
              }
            }
            console.log(
              "时间22" + new Date(),
              "用户ID" + question.data.WDQuestion[0].questioner,
              "订单编号" + orderNum1,
              "订单ID" + IDS,
              "价钱" + release
            );
            ////给问题发布者账单加入一条收入数据
            let data2 = await INSERT(
              "post",
              "",
              '  insert_bill(objects: {type: 1, state: 0, time: "' +
                new Date() +
                '", userid: ' +
                question.data.WDQuestion[0].questioner +
                ', orderNum: "' +
                orderNum1 +
                '", orderid: ' +
                IDS +
                ', Price: "' +
                release +
                '", evaluate: "",classification:0}) {affected_rows    returning {    id  }  }'
            );
            console.log(
              "发布者账单存入成功！" + data2.data.insert_bill.returning[0].id
            );
            ///通过问题ID查询问题内容
            let questions = await QUERYED(
              "POST",
              "",
              "  WDQuestion(where: {id: {_eq: " + IDS + "}}) { questionTitle  }"
            );
            // console.log('收到消息的ID'+question.data.WDQuestion[0].questioner,"时间"+new Date()+"发消息的ID"+user,"问题"+questions.data.WDQuestion[0].questionTitle,"发布者价钱"+release,"采纳者价钱"+answer)
            ////给问题发布者发一条消息通知他查看
            let seeData = await INSERT(
              "post",
              "",
              "  insert_MessageNotification(objects: {type: 1, userid: " +
                question.data.WDQuestion[0].questioner +
                ', creatime: "' +
                new Date() +
                '", beuserid:' +
                user +
                ',message:"' +
                questions.data.WDQuestion[0].questionTitle +
                '",bill : ' +
                release +
                ",state :2}) { affected_rows  }"
            );
            if (seeData.data.insert_MessageNotification.affected_rows == 1) {
              /////问题发布者的消息发送成功！
              // 判断是单人回答还是多人回答
              if (person == 0) {
                ///如果是单人回答
                /////////计算回答者的应获得的
                this.Single(
                  AnswerRefID.data.WDUserQuestionAnswerRef[0].user,
                  answer,
                  IDS,
                  questions.data.WDQuestion[0].questionTitle,
                  user
                ); //调用单人回答
              } else if (person == 1) {
                //如果是多人回答
                ////通过外部团队ID查找回答的用户ID
                console.log(
                  "外部团队ID：" +
                    AnswerRefID.data.WDUserQuestionAnswerRef[0]
                      .external_teamid +
                    "外部问题ID：" +
                    AnswerRefID.data.WDUserQuestionAnswerRef[0]
                      .external_problemid
                );
                let people_answer = await QUERYED(
                  "POST",
                  "",
                  "people_answer(where: {team_id : {_eq: " +
                    AnswerRefID.data.WDUserQuestionAnswerRef[0]
                      .external_teamid +
                    "},ques_id  : {_eq: " +
                    AnswerRefID.data.WDUserQuestionAnswerRef[0]
                      .external_problemid +
                    "}}) {   id  user_id  }"
                );
                //////每个人都应该获得的价钱
                let External_price =
                  Number(answer) /
                  Number(people_answer.data.people_answer.length);
                for (
                  let i = 0;
                  i < people_answer.data.people_answer.length;
                  i++
                ) {
                  ///循环扣费
                  console.log(
                    "多人团队用户ID：" +
                      people_answer.data.people_answer[i].user_id
                  );
                  ///通过用户ID查找该用户的账户余额
                  let MoenyAdo = await QUERYED(
                    "POST",
                    "",
                    "  PCUsers(where: {id: {_eq: " +
                      people_answer.data.people_answer[i].user_id +
                      "}}) {   Money  }"
                  );
                  ////修改该用户的账户余额
                  MoenyAdo.data.PCUsers[0].Money =
                    Number(MoenyAdo.data.PCUsers[0].Money) +
                    Number(External_price);
                  ////修改返回数据库
                  let updateMoeny1 = await UPDATE(
                    "POST",
                    "",
                    "  update_User(where: {id: {_eq: " +
                      people_answer.data.people_answer[i].user_id +
                      "}}, _set: {Money: " +
                      MoenyAdo.data.PCUsers[0].Money +
                      "}) {  affected_rows  }"
                  );
                  if (updateMoeny1.data.update_User.affected_rows == 1) {
                    //修改成功！
                    //////////////////////////生成订单编号//////////////////////////
                    ////采纳者的  people_answer.data.people_answer[i].user_id（采纳者id）
                    let ddata1 = await QUERYED(
                      "post",
                      "",
                      "  WDALLBill(where: {userid: {_eq: " +
                        people_answer.data.people_answer[i].user_id +
                        "}}) {  time  orderNum  id   }"
                    );
                    //  生成回答问题者的订单编号
                    let orderNum1 = "";
                    let num1 = String(
                      formatZero(people_answer.data.people_answer[i].user_id, 7)
                    );
                    /////生成订单编号
                    if (ddata1.data.WDALLBill.length < 1) {
                      orderNum1 = getTimeFormat(new Date()) + num1 + "001";
                    } else {
                      for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
                        if (
                          getTimeFormat(new Date()) ==
                          getTimeFormat(ddata1.data.WDALLBill[i].time)
                        ) {
                          let sic =
                            ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 +
                            1;
                          orderNum1 =
                            getTimeFormat(new Date()) +
                            num1 +
                            String(formatZero(sic, 3));
                        } else {
                          orderNum1 = getTimeFormat(new Date()) + num1 + "001";
                        }
                      }
                    }
                    console.log(
                      "时间" + new Date(),
                      "用户ID" + people_answer.data.people_answer[i].user_id,
                      "订单编号" + orderNum1,
                      "订单ID" + IDS,
                      "价钱" + External_price
                    );
                    ////给采纳者账单加入一条收入数据
                    let data2 = await INSERT(
                      "post",
                      "",
                      '  insert_bill(objects: {type: 1, state: 0, time: "' +
                        new Date() +
                        '", userid: ' +
                        people_answer.data.people_answer[i].user_id +
                        ', orderNum: "' +
                        orderNum1 +
                        '", orderid: ' +
                        IDS +
                        ', Price: "' +
                        External_price +
                        '", evaluate: "",classification:0}) {affected_rows    returning {    id  }  }'
                    );
                    console.log(
                      "采纳者账单存入成功！" +
                        data2.data.insert_bill.returning[0].id
                    );
                    ///通过问题ID查询问题内容
                    let questions = await QUERYED(
                      "POST",
                      "",
                      "  WDQuestion(where: {id: {_eq: " +
                        IDS +
                        "}}) { questionTitle  }"
                    );
                    // console.log('收到消息的ID'+question.data.WDQuestion[0].questioner,"时间"+new Date()+"发消息的ID"+user,"问题"+questions.data.WDQuestion[0].questionTitle,"发布者价钱"+release,"采纳者价钱"+answer)
                    ////给采纳者发一条消息通知他查看
                    let seeData = await INSERT(
                      "post",
                      "",
                      "  insert_MessageNotification(objects: {type: 1, userid: " +
                        people_answer.data.people_answer[i].user_id +
                        ', creatime: "' +
                        new Date() +
                        '", beuserid:' +
                        user +
                        ',message:"' +
                        questions.data.WDQuestion[0].questionTitle +
                        '",bill : ' +
                        External_price +
                        ",state :2}) { affected_rows  }"
                    );
                    if (
                      seeData.data.insert_MessageNotification.affected_rows == 1
                    ) {
                      console.log("消息发送成功！");
                    }
                  }
                }
                this.$message({
                  type: "success",
                  message: "购买成功！"
                });
                this.charge(); ///展开全部
              }
            }
          }
        }
      } else {
        ///生成账单
        let data1 = await INSERT(
          "post",
          "",
          '  insert_bill(objects: {type: 1, state: 3, time: "' +
            new Date() +
            '", userid: ' +
            user +
            ', orderNum: "' +
            orderNum +
            '", orderid: ' +
            IDS +
            ', Price: "' +
            this.viewPrice +
            '", evaluate: ""}) {affected_rows  }'
        );
        console.log(data1.data.insert_bill.affected_rows);
        this.$message({
          type: "success",
          message: "购买失败！"
        });
      }
    },
    ////单人回答、、采纳者单独的扣费/消息/账单信息
    async Single(AnswerRefIDuser, answer, IDS, questionTitle, user) {
      ///根据回答问题的ID查找该用户的知识币余额
      let MoenyAdo = await QUERYED(
        "POST",
        "",
        "  PCUsers(where: {id: {_eq: " + AnswerRefIDuser + "}}) {   Money  }"
      );
      MoenyAdo.data.PCUsers[0].Money =
        Number(MoenyAdo.data.PCUsers[0].Money) + Number(answer); ///采纳者的账户余额
      ///修改获得采纳者的账户余额
      let updateMoeny1 = await UPDATE(
        "POST",
        "",
        "  update_User(where: {id: {_eq: " +
          AnswerRefIDuser +
          "}}, _set: {Money: " +
          MoenyAdo.data.PCUsers[0].Money +
          "}) {  affected_rows  }"
      );
      if (updateMoeny1.data.update_User.affected_rows == 1) {
        //////////////////采纳者添加一条账单
        ////采纳者的  AnswerRefIDuser（采纳者id）
        let ddata1 = await QUERYED(
          "post",
          "",
          "  WDALLBill(where: {userid: {_eq: " +
            AnswerRefIDuser +
            "}}) {  time  orderNum  id   }"
        );
        //  生成回答问题者的订单编号
        let orderNum1 = "";
        let num1 = String(formatZero(AnswerRefIDuser, 7));
        /////生成订单编号
        if (ddata1.data.WDALLBill.length < 1) {
          orderNum1 = getTimeFormat(new Date()) + num1 + "001";
        } else {
          for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
            if (
              getTimeFormat(new Date()) ==
              getTimeFormat(ddata1.data.WDALLBill[i].time)
            ) {
              let sic = ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
              orderNum1 =
                getTimeFormat(new Date()) + num1 + String(formatZero(sic, 3));
            } else {
              orderNum1 = getTimeFormat(new Date()) + num1 + "001";
            }
          }
        }
        console.log(
          "时间" + new Date(),
          "用户ID" + AnswerRefIDuser,
          "订单编号" + orderNum1,
          "订单ID" + IDS,
          "价钱" + answer
        );
        ////给采纳者账单加入一条收入数据
        let data2 = await INSERT(
          "post",
          "",
          '  insert_bill(objects: {type: 1, state: 0, time: "' +
            new Date() +
            '", userid: ' +
            AnswerRefIDuser +
            ', orderNum: "' +
            orderNum1 +
            '", orderid: ' +
            IDS +
            ', Price: "' +
            answer +
            '", evaluate: "",classification:0}) {affected_rows    returning {    id  }  }'
        );
        console.log(
          "采纳者账单存入成功！" + data2.data.insert_bill.returning[0].id
        );
        ///通过问题ID查询问题内容
        // let questions=await QUERYED("POST","",'  WDQuestion(where: {id: {_eq: '+IDS+'}}) { questionTitle  }');
        // console.log('收到消息的ID'+question.data.WDQuestion[0].questioner,"时间"+new Date()+"发消息的ID"+user,"问题"+questions.data.WDQuestion[0].questionTitle,"发布者价钱"+release,"采纳者价钱"+answer)
        ////给采纳者发一条消息通知他查看
        let seeData = await INSERT(
          "post",
          "",
          "  insert_MessageNotification(objects: {type: 1, userid: " +
            AnswerRefIDuser +
            ', creatime: "' +
            new Date() +
            '", beuserid:' +
            user +
            ',message:"' +
            questionTitle +
            '",bill : ' +
            answer +
            ",state :2}) { affected_rows  }"
        );
        if (seeData.data.insert_MessageNotification.affected_rows == 1) {
          this.$message({
            type: "success",
            message: "购买成功！"
          });
          this.charge(); ///展开全部
        }
      }
    },
    ////收费展开全部
    async charge() {
      this.basiced = []; //清空
      let a = null;
      let data = await QUERYED(
        "post",
        "",
        " WDUserQuestionAnswerRef(where:{wdquestionid: {_eq: " +
          this.IDS +
          "}, adopt: {_eq: 1},person : {_eq: 0}}) {wdanswerref charge adopt user  answers wdquestionid id img videos name  intro  answer  imge answerFiles   createtimes  fabuloused }"
      );
      console.log("详情显示：" + data.data.WDUserQuestionAnswerRef.length);
      for (let i = 0; i < data.data.WDUserQuestionAnswerRef.length; i++) {
        a = data.data.WDUserQuestionAnswerRef[i]; ///显示回答
        a.img = this.api.LoginURL.concat(a.img);
        a.imge = this.api.LoginURL.concat(a.imge); //转换图片
        a.videos = this.api.LoginURL.concat(a.videos); //转换视频
        a.answerFiles = this.api.LoginURL.concat(a.answerFiles); //转换附件
        a.createtimes = formatDate(Date.parse(a.createtimes));
        this.basiced.push(a);
      }
      this.answerNum = this.basiced.length; //答案个数
      this.visable = 1; // 显示全部
      this.Type = 0; //不显示展开按钮
    },
    ///展开回答（免费）
    async open() {
      this.basiced = []; //清空
      let a = null;
      let data = await QUERYED(
        "post",
        "",
        " WDUserQuestionAnswerRef(where:{wdquestionid: {_eq: " +
          this.IDS +
          "},_not: {adopt: {_eq: 2}}}) {adopt wdanswerref user viewPrice answers wdquestionid  img videos name  intro  answer  imge answerFiles id  createtimes  fabuloused }"
      );
      console.log("详情显示：" + data.data.WDUserQuestionAnswerRef.length);
      for (let i = 0; i < data.data.WDUserQuestionAnswerRef.length; i++) {
        a = data.data.WDUserQuestionAnswerRef[i]; ///显示回答
        a.img = this.api.LoginURL.concat(a.img);
        a.imge = this.api.LoginURL.concat(a.imge); //转换图片
        a.videos = this.api.LoginURL.concat(a.videos); //转换视频
        a.answerFiles = this.api.LoginURL.concat(a.answerFiles); //转换附件
        a.createtimes = formatDate(Date.parse(a.createtimes));
        this.basiced.push(a);
      }
      this.answerNum = this.basiced.length; //答案个数
      // 显示全部
      this.visable = 1;
      this.Type = 0; //不显示展开按钮
    },
    //   初始化
    async init() {
      ///////多人问答的引用暂不展示
      console.log("问题ID：" + this.IDS);
      this.basiced = []; //清空
      let a = null;
      let data = []; //数组
      ///通过问题ID判断是否是免费问题
      let info = await QUERYED(
        "post",
        "",
        "  WDNewQuestion(where: {id: {_eq: " + this.IDS + "}}) {   isViewFree }"
      );
      //  回答个数
      let dataa = await QUERYED(
        "post",
        "",
        " WDUserQuestionAnswerRef(where:{wdquestionid: {_eq: " +
          this.IDS +
          "},person : {_eq: 0},_not: {adopt: {_eq: 2}}}) {wdanswerref charge adopt user  answers wdquestionid id img videos name  intro  answer  imge answerFiles   createtimes  fabuloused }"
      );

      if (info.data.WDNewQuestion[0].isViewFree == 1) {
        //如果是免费
        data = await QUERYED(
          "post",
          "",
          " WDUserQuestionAnswerRef(where:{wdquestionid: {_eq: " +
            this.IDS +
            "},person : {_eq: 0},_not: {adopt: {_eq: 2}}} , limit: 1) {wdanswerref charge adopt user  answers wdquestionid id img videos name  intro  answer  imge answerFiles   createtimes  fabuloused }"
        );
        if (data.data.WDUserQuestionAnswerRef.length == 0) {
          //如果没有回答
          this.Type = 0; //不显示展开
          this.answerNum = data.data.WDUserQuestionAnswerRef.length; //答案个数
        } else {
          //如果有回答
          this.Type = 1; //显示展开
          this.answerNum = dataa.data.WDUserQuestionAnswerRef.length; //答案个数
        }
      } else if (info.data.WDNewQuestion[0].isViewFree == 2) {
        //如果收费
        data = await QUERYED(
          "post",
          "",
          "  WDUserQuestionAnswerRef(where:{wdquestionid: {_eq: " +
            this.IDS +
            "},person : {_eq: 0}, adopt: {_eq: 1}}, limit: 1) {adopt wdanswerref user viewPrice answers wdquestionid  img videos name  intro  answer  imge answerFiles id  createtimes  fabuloused }"
        );
        if (data.data.WDUserQuestionAnswerRef.length == 0) {
          //如果没有回答
          this.Type = 0; //不显示展开
          this.answerNum = data.data.WDUserQuestionAnswerRef.length; //答案个数
        } else {
          ///如果有回答
          this.Type = 2; //显示展开(收费)
          this.answerNum = 1; //回答个数
          //查看有无没有被采纳
          //  let  data=  await   QUERYED("post","",'  WDUserQuestionAnswerRef(where:{wdquestionid: {_eq: '+this.IDS+'}, adopt: {_eq: 1}}, limit: 1) {adopt wdanswerref user viewPrice answers wdquestionid  img videos name  intro  answer  imge answerFiles id  createtimes  fabuloused }');
        }
      }
      console.log("详情显示：" + data.data.WDUserQuestionAnswerRef.length);
      if (data.data.WDUserQuestionAnswerRef.length != 0) {
        for (let i = 0; i < data.data.WDUserQuestionAnswerRef.length; i++) {
          a = data.data.WDUserQuestionAnswerRef[i]; ///显示回答

          a.img = this.api.LoginURL.concat(a.img);
          a.imge = this.api.LoginURL.concat(a.imge); //转换图片
          a.videos = this.api.LoginURL.concat(a.videos); //转换视频
          a.answerFiles = this.api.LoginURL.concat(a.answerFiles); //转换附件
          a.createtimes = formatDate(Date.parse(a.createtimes));
          this.basiced.push(a);
        }
        // console.log("价钱："+data.data.WDUserQuestionAnswerRef[0].viewPrice)
        this.viewPrice = data.data.WDUserQuestionAnswerRef[0].viewPrice; //查看分成的价格
        this.visable = 0; //隐藏
      }
    }
  }
};
</script>
<style scoped>
.answer {
  margin-top: 10px;
  width: 100%;
  /* border: 1px solid red; */
}
.away {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #686868;
  cursor: pointer;
}
.open {
  width: 100%;
  /* border:1px solid red; */
  text-align: center;
  color: #0084ff;
  font-size: 13px;
  cursor: pointer;
}
.answer_top {
  width: 100%;
  height: 30px;
  line-height: 30px;
  /* border: 1px solid rebeccapurple; */
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #535353;
  margin-bottom: 20px;
}
.answer_num {
  font-size: 14px;
}
.open {
  width: 100%;
  /* border:1px solid red; */
  text-align: center;
  color: #0084ff;
  font-size: 13px;
  cursor: pointer;
}
.opens {
  width: 100%;
  /* border:1px solid red; */
  text-align: center;
  color: #888888;
  font-size: 13px;
  cursor: pointer;
}
.personal_left {
  /* width: 70px;
height:70px;
float: left;
margin-top: 10px;
border-radius: 50%; */
  /* border: 1px solid red; */
  /* display: inline-block; */
}
.answer_content {
  width: 98%;
  margin: auto;
  /* display: inline-block; */
}
.answer_content_top {
  width: 100%;
  height: 60px;
  display: inline-block;
  /* border: 1px solid darkorange; */
}
.personal_left {
  width: 60px;
  height: 60px;
  display: inline-block;
  border-radius: 50%;
  /* border: 1px solid darkgreen; */
  margin-right: 20px;
}
.personal_left_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* margin-left: 10px; */
}
.answer_content_center {
  width: 90%;
  /* height:60px; */
  /* border: 1px solid darkviolet; */
  display: inline-block;
  /* vertical-align: top; */
}
.answer_content_center > div {
  width: 100%;
  display: inline-block;
  /* border: 1px solid green; */
  line-height: 35px;
  vertical-align: bottom;
}
.answer_content_bottom {
  margin-top: 10px;
  width: 100%;
  /* border: 1px solid red; */
}
.answer_top_bottom {
  width: 85%;
  height: 2px;
  background: #e5e5e5;
  border: none;
  /* display: inline-block; */
  float: right;
  margin-left: 20px;
  position: relative;
  top: 20px;
}
.personal_name {
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  /* border: 1px solid red; */
}
/* .personal_names:hover{
  text-decoration:underline 
} */
.personal_autograph {
  font-size: 12px;
  color: #c0c0c0;
}
.details_content {
  font-size: 13px;
  line-height: 30px;
  width: 100%;
  word-wrap: break-word;
}
.time {
  color: gray;
  font-size: 13px;
  width: 100%;
  /* border: 1px solid red; */
  text-align: right;
}
.yin1 {
  width: 100%;
  height: 25px;
  /* border: 1px solid red; */
  text-align: right;
  margin-top: 5px;
}
.yin {
  display: inline-block;
  background-image: url("../../../../../assets/Yin1 (1).png");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  /* margin-right: 10px; */
  /* background-position-x: 10px;
  background-position-y: 7px; */
  /* background: #FFFFFF; */
  /* border: 1px solid #DCDCDC; */
  /* border-radius: 5%; */
}
.up {
  /* Up.png */
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../../../assets/Up.png");
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  left: 2px;
}
.details_content_open {
  width: 100%;
  text-align: right;
  /* border: 1px solid red; */
  position: relative;
  top: -7px;
}
</style>
